import React, {useState} from "react";

import './single-comments-form.scss'
import {useRecoilValue} from "recoil";
import {wpClientSelector} from "../../state/api";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";

const SingleCommentsForm = ({postId}) => {
  const wpClient = useRecoilValue(wpClientSelector)
  const [saving, setSaving] = useState(false)
  const {t} = useTranslation()

  const onCommitSubmit = (ev) => {
    if (saving) {
      return
    }
    ev.stopPropagation()
    ev.preventDefault()
    const authorName = document.getElementById('author_name').value;
    const authorEmail = document.getElementById('author_email').value;
    const authorUrl = document.getElementById('author_url').value;
    const commentContent = document.getElementById('author_message').value;
    setSaving(true)
    wpClient.comments()
      .create({
        author_email: authorEmail,
        author_name: authorName,
        author_url: authorUrl,
        content: commentContent,
        post: postId,
      })
      .then(function () {
        setSaving(false)
        if (window.confirm(t("comment-waiting-for-approval"))) {
          window.location.reload()
        }
      })
      .catch(error => {
        console.error(error)
        setSaving(false)
      });
  }

  return (
    <div className="single-blog__comment__form bg-one">
      <div className="m-25px-b">
        <h4>{t("leave-a-comment")}</h4>
        <p>
          {t("comments-form-indications")}
        </p>
      </div>
      <form onSubmit={onCommitSubmit} className="comment-form">
        <div className="row">
          <div className="col-lg-6">
            <div className="form-group">
              <input
                type="text"
                name="name"
                id="author_name"
                required
                placeholder={`${t("name")}*`}
              />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <input
                type="email"
                name="email"
                id="author_email"
                required
                placeholder={`${t("email")}*`}
              />
            </div>
          </div>
          <div className="col-lg-12">
            <div className="form-group">
              <input
                type="url"
                name="Website"
                id="author_url"
                placeholder={`${t("website")}`}
              />
            </div>
          </div>
          <div className="col-lg-12">
            <div className="form-group">
              <textarea
                name="messages"
                id="author_message"
                cols="30"
                rows="5"
                placeholder={t("enter-comment-here")}></textarea>
            </div>
          </div>
        </div>
        <div className="m-20px-t">
          <input id="single-comments-form-submit" style={{display: "none"}} type="submit" name="submit"/>
          <a className="button button__primary"
             onClick={() => document.getElementById('single-comments-form-submit').click()}>
            <span>
              {
                saving
                ? t("posting-comment")
                : t("post-a-comment")
              }
            </span>
          </a>
        </div>
      </form>
    </div>
  )
}

SingleCommentsForm.propTypes = {
  postId: PropTypes.number.isRequired,
}

export default SingleCommentsForm