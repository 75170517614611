/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from "react";
import {Link} from "react-router-dom";
import {socialLink} from "../global";
import {useTranslation} from "react-i18next";
import axios from "axios";

const Contact = ({isBg}) => {
  const {t} = useTranslation()
  const [contact, setContact] = useState({})
  const [sending, setSending] = useState(false)

  const onSubmit = (ev) => {
    ev.stopPropagation()
    ev.preventDefault()
    if (sending) {
      return
    }
    setSending(true)
    axios
      .post(process.env.REACT_APP_WP_JSON_CONTACT, {
        "your-name": contact.name,
        "your-email": contact.email,
        "your-subject": contact.subject,
        "your-message": contact.message
      }, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(function (resp) {
        const respData = resp.data
        if (respData.status !== "mail_sent") {
          window.alert(t("impossible-to-send-message"))
          setSending(false)
        } else {
          window.alert(t("message-sent-succesfully"))
          setSending(false)
          window.location.reload()
        }
      })
      .catch(function () {
        window.alert(t("impossible-to-send-message"))
        setSending(false)
      });
  }

  return (
    // <!-- ========== Contact section start ========== -->
    <section
      id="contact"
      className={`p-80px-tb ${isBg === "yes" ? "bg-one" : ""}`}
    >
      <div className="container">
        <div className="row">
          <div className="col-xl-6 offset-xl-3 col-lg-10 offset-lg-1">
            <div className="section-title-center text-center">
              <span>{t("contact")}</span>
              <h2 className="display-6">{t("write-me-anything")}</h2>
              <div className="section-divider divider-traingle"></div>
            </div>
          </div>
        </div>
        <div className="row">
          {/* <!--  contact form area start --> */}
          <div
            className="col-lg-8 col-md-6 col-sm-12 m-25px-b"
            data-aos="fade-right"
            data-aos-duration="1000"
            data-aos-delay="200"
          >
            <div className="contact-form-box">
              <form
                id="contact-form"
                method="post"
                onSubmit={onSubmit}>
                <div className="message col">
                  <p className="email-loading alert alert-warning">
                    <img src="/assets/images/loading.gif" alt=""/>
                    &nbsp;&nbsp;&nbsp;{t("sending")}...
                  </p>
                  <p className="email-success alert alert-success">
                    <i className="icofont-check-circled"></i> {t("your-quote-has-been-succesfully-sent")}
                  </p>
                  <p className="email-failed alert alert-danger">
                    <i className="icofont-close-circled"></i> {t("something-went-wrong")}
                  </p>
                </div>
                <div className="mb13">
                  <input
                    name="name"
                    className="contact-name"
                    id="contact-name"
                    type="text"
                    placeholder={t("your-name")}
                    onChange={(ev) => setContact({...contact, name: ev.target.value})}
                    required
                  />
                </div>
                <div className="mb13">
                  <input
                    name="email"
                    className="contact-email"
                    id="contact-email"
                    type="email"
                    placeholder={t("your-email")}
                    onChange={(ev) => setContact({...contact, email: ev.target.value})}
                    required
                  />
                </div>
                <div className="mb13">
                  <input
                    name="subject"
                    className="contact-subject"
                    id="contact-subject"
                    type="text"
                    placeholder={t("subject")}
                    onChange={(ev) => setContact({...contact, subject: ev.target.value})}
                    required
                  />
                </div>
                <div className="mb30">
                  <textarea
                    name="message"
                    className="contact-message"
                    id="contact-message"
                    placeholder={t("your-message")}
                    onChange={(ev) => setContact({...contact, message: ev.target.value})}
                    required
                  ></textarea>
                </div>
                <button
                  type="submit"
                  disabled={sending}
                  className="button button__primary align-items-center">
                  <span>
                    {
                      sending
                        ? t("sending")
                        : t("send-now")
                    }
                  </span>
                </button>
              </form>
            </div>
          </div>
          {/* <!--  contact form area end --> */}
          {/* <!-- contact information start --> */}
          <div
            className="col-lg-4 col-md-6 col-sm-12 m-25px-b"
            data-aos="fade-left"
            data-aos-duration="1000"
            data-aos-delay="200"
          >
            <div className="contact__address p-30px">
              <ul className="contact__address__content">
                <li>
                  <span>Email</span>
                  <a href="mailto:antonellaroncarolo@gmail.com">antonellaroncarolo@gmail.com</a>
                </li>
              </ul>
              <h4>{t("connect-author-socials")}</h4>
              <ul className="social-icon mt-3">
                {socialLink.facebook === "" ? (
                  ""
                ) : (
                  <li>
                    <a target="_blank" href={socialLink.facebook}>
                      <img
                        className="img-fluid"
                        src="/assets/icons/facebook.svg"
                        alt="icon"
                        width="25"
                        height="25"
                      />
                    </a>
                  </li>
                )}
                {socialLink.twitter === "" ? (
                  ""
                ) : (
                  <li>
                    <a target="_blank" href={socialLink.twitter}>
                      <img
                        className="img-fluid"
                        src="/assets/icons/twitter.svg"
                        alt="icon"
                        width="25"
                        height="25"
                      />
                    </a>
                  </li>
                )}
                {socialLink.linkedin === "" ? (
                  ""
                ) : (
                  <li>
                    <a target="_blank" href={socialLink.linkedin}>
                      <img
                        className="img-fluid"
                        src="/assets/icons/linkedin.svg"
                        alt="icon"
                        width="25"
                        height="25"
                      />
                    </a>
                  </li>
                )}
                {socialLink.youtube === "" ? (
                  ""
                ) : (
                  <li>
                    <a target="_blank" href={socialLink.youtube}>
                      <img
                        className="img-fluid"
                        src="/assets/icons/youtube-play.svg"
                        alt="icon"
                        width="25"
                        height="25"
                      />
                    </a>
                  </li>
                )}
                {socialLink.whatsapp === "" ? (
                  ""
                ) : (
                  <li>
                    <a target="_blank" href={socialLink.whatsapp}>
                      <img
                        className="img-fluid"
                        src="/assets/icons/whatsapp.svg"
                        alt="icon"
                        width="25"
                        height="25"
                      />
                    </a>
                  </li>
                )}
                {socialLink.instagram === "" ? (
                  ""
                ) : (
                  <li>
                    <a target="_blank" href={socialLink.instagram}>
                      <img
                        className="img-fluid"
                        src="/assets/icons/instagram.svg"
                        alt="icon"
                        width="25"
                        height="25"
                      />
                    </a>
                  </li>
                )}
              </ul>
            </div>
          </div>
          {/* <!-- contact information end --> */}
        </div>
      </div>
    </section>
    // <!-- ========== Contact section end ========== -->
  );
};

export default Contact;
