export const siteLogo = {
    logoText: "Antonella Roncarolo",  // Add your site logo here
};

export const socialLink = {
    facebook: "https://www.facebook.com/antonella.roncarolo",
    twitter: "https://www.twitter.com/Anto_roncarolo",
    linkedin: "https://www.linkedin.com/in/antonella-roncarolo-6758703a/",
    youtube: "",
    whatsapp: "",
    instagram: "https://www.instagram.com/antonella_roncarolo"
};

export const mailchimpLink = {
    link: (process.env.REACT_APP_MAILCHAIMP) // Add your mailchimp list link here
};

export const contactFormAction = {
    link: (process.env.REACT_APP_CONTACTFORM)  // Add for link here from https://formspree.io
}

export const contactMap = {
    link: (process.env.REACT_APP_CONTACTMAP) // Add google map link from here: https://www.embedgooglemap.net/
}
