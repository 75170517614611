import {decodeHtml} from "../utils";

class Book {
  constructor(wpBook) {
    this.id = wpBook.id;
    this.title = decodeHtml(wpBook.title.rendered)
    this.content = wpBook.content.rendered
    this.slug = wpBook.slug.substring(0, wpBook.slug.length - 3)
    this.releaseDate = new Date(wpBook.date)
    this.commentsAllowed = wpBook["comment_status"] === "open"
    this.coverMediaId = wpBook.acf["cover_media_id"]
    this.featured = wpBook.acf["featured"]
    this.shortDescription = wpBook.acf["short_description"]
    this.slogan = wpBook.acf["slogan"]
    this.tag = wpBook.acf["tag"]
    if (wpBook.acf["cta_text"] !== "nascondi") {
      this.ctaText = wpBook.acf["cta_text"]
      this.ctaLink = wpBook.acf["cta_link"]
    }
    this.embedded = wpBook["_embedded"]
    if (this.embedded) {
      this.commentsNumber = (this.embedded.replies && this.embedded.replies[0]) ? this.embedded.replies[0].length : 0
    }
  }
}

export default Book