import {BrowserRouter, Route, Routes, useLocation} from "react-router-dom";
import "./assets/css/margins-paddings.css";
import Home from "./components/pages/home";

import AOS from "aos";
import React, {useCallback, useEffect, useState} from "react";
import "./assets/css/aos.css";
import AllBlog from "./components/pages/all-blog";
import AllEvent from "./components/pages/all-event";
import SingleBlog from "./components/pages/single-blog";
import SingleEvent from "./components/pages/single-event";
import footerData from "./data/footer.json";
import headerData from "./data/header.json";
import {useRecoilValue} from "recoil";
import AllBook from "./components/pages/all-book";
import {useTranslation} from "react-i18next";
import {wpClientSelector} from "./state/api";
import SingleBook from "./components/pages/single-book";

function ScrollToTop() {
  const {pathname} = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
}

const App = () => {
  const {header, headerBlog, headerBook} = headerData;
  const {footer, footerBooks, footerBlog} = footerData;
  const {t, i18n} = useTranslation()
  const [locale, setLocale] = useState(i18n.resolvedLanguage)
  const [langTagId, setLangTagId] = useState(-1)
  const wpClient = useRecoilValue(wpClientSelector)
  const [error, setError] = useState(false)

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  useEffect(() => {
    if (!locale) {
      return
    }
    wpClient.tags()
      .slug(locale)
      .perPage(1)
      .embed()
      .then(function (response) {
        if (response.length === 0) {
          console.error("resolved language is not compatible with the portal")
          setError(true)
        }
        setLangTagId(response[0].id)
      })
      .catch(error => {
        console.error(error)
        setError(true)
      });
  }, [locale]);

  const handleLanguageChanged = useCallback(() => {
    setLocale(i18n.resolvedLanguage)
  }, []);

  useEffect(() => {
    i18n.on('languageChanged', handleLanguageChanged);
    return () => {
      i18n.off('languageChanged', handleLanguageChanged);
    };
  }, [handleLanguageChanged]);

  if (langTagId < 0) {
    return <></>
  }

  if (error) {
    return (
      <p style={{width: "100%", height: "100%", marginTop: "10em", textAlign: "center"}}>
        {t("error")}
      </p>
    )
  }

  return (
    <div className="section-wrapper">
      <div id="preLoader"></div>
      <BrowserRouter>
        <ScrollToTop/>
        <Routes>
          <Route
            path="/"
            element={<Home header={header} footer={footer} langTagId={langTagId}/>}
          />
          <Route
            path="/books"
            element={<AllBook header={headerBook} footer={footerBooks} langTagId={langTagId}/>}
          />
          <Route
            path="/books/:slug"
            element={<SingleBook header={headerBook} footer={footerBooks} locale={locale}/>}
          />
          <Route
            path="/blog"
            element={<AllBlog header={headerBlog} footer={footerBlog}/>}
          />
          <Route
            path="/blog/:slug"
            element={<SingleBlog header={headerBlog} footer={footerBlog}/>}
          />
          <Route
            path="/events"
            element={<AllEvent header={headerBlog} footer={footerBlog}/>}
          />
          <Route
            path="/events/:slug"
            element={<SingleEvent header={headerBlog} footer={footerBlog}/>}
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
