import React from "react";
import AuthorV2 from "../authorv2";
import Contact from "../contact";
import Ctav5 from "../ctav5";
import FunFacts from "../fun-facts";
import Footer from "../global/footer";
import Header from "../global/header";
import Hero from "../hero";
import Testimonials from "../testimonials";
import Booksv1 from "../booksv1";
import PropTypes from "prop-types";

const Home = ({header, footer, langTagId}) => {
  const {menu} = footer;

  return (
    <>
      <Header header={header}/>
      <Hero isBg langTagId={langTagId}/>
      <AuthorV2 isBg=""/>
      <FunFacts isBg langTagId={langTagId} />
      <Booksv1 isBg={false} langTagId={langTagId} />
      <Ctav5 isBg="yes"/>
      <Testimonials isBg={false} langTagId={langTagId}/>
      <Contact isBg="yes"/>
      <Footer isBg="" menu={menu}/>
    </>
  );
}

Home.propTypes = {
  langTagId: PropTypes.number.isRequired,
  header: PropTypes.object.isRequired,
  footer: PropTypes.object.isRequired,
}

export default Home;
