import {selector} from "recoil";
import WPAPI from "wpapi";

const wpClientSelector = selector({
  key: 'wpClientSelector',
  get: () => {
    const wp = new WPAPI({
      endpoint: process.env.REACT_APP_WP_JSON,
    });
    wp.books = wp.registerRoute('wp/v2', '/books/(?P<id>)', {
      params: ['order', 'orderby', 'tags', 'meta_key', 'meta_value'],
    });
    wp.milestones = wp.registerRoute('wp/v2', '/milestones/(?P<id>)', {
      params: ['tags'],
    });
    wp.testimonials = wp.registerRoute('wp/v2', '/testimonials/(?P<id>)', {
      params: ['tags'],
    });
    wp.blog = wp.registerRoute('wp/v2', '/blog/(?P<id>)', {
      params: ['tags'],
    });
    wp.events = wp.registerRoute('wp/v2', '/events/(?P<id>)', {
      params: ['tags'],
    });
    return wp
  },
});

export {wpClientSelector}