import React from "react";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

const Ctav5 = ({isBg}) => {
  const {t} = useTranslation()

  return (
    // <!-- ========== CTA v5 section start ========== -->
    <section className={`section-padding ctav5  ${isBg === "yes" ? "bg-one" : ""}`}
             style={{backgroundImage: `url(assets/images/cta-author-1-bg.jpeg)`}}>
      <Link to="/books/arcani-sentieri">
        <div className="container">
          <div className="row">
            <div className="ctav5__content">
              <h2
                className="display-3"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="150">
                {t("cta-author-1")}
              </h2>
              <ul>
                <li
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="200"
                >
                  <img className="img-fluid" src="/assets/icons/star.svg" alt="Star Icon" width="25" height="25"/>
                </li>
                <li
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="250"
                >
                  <img className="img-fluid" src="/assets/icons/star.svg" alt="Star Icon" width="25" height="25"/>
                </li>
                <li
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="300"
                >
                  <img className="img-fluid" src="/assets/icons/star.svg" alt="Star Icon" width="25" height="25"/>
                </li>
                <li
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="350"
                >
                  <img className="img-fluid" src="/assets/icons/star.svg" alt="Star Icon" width="25" height="25"/>
                </li>
                <li
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="400"
                >
                  <img className="img-fluid" src="/assets/icons/star.svg" alt="Star Icon" width="25" height="25"/>
                </li>
              </ul>
              <p
                style={{color: "white", fontFamily: "Poppins, sans-serif"}}
                className="fs-5"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="500">
                {t("readers-review")} <br/>
                {t("readers-review-sub")}
              </p>
            </div>
          </div>
        </div>
      </Link>
    </section>
    // <!-- ========== CTA v5 section end ========== -->
  );
};

export default Ctav5;
