import "bootstrap/dist/css/bootstrap.min.css";
import "glightbox/dist/css/glightbox.css";
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import "./assets/sass/main.scss";
import './i18n'
import {RecoilRoot} from "recoil";
import {hydrate} from "react-dom";

const root = ReactDOM.createRoot(document.getElementById('root'));
const rootElement = document.getElementById("root");

const RootApp = () => {
  return (
    <React.StrictMode>
      <RecoilRoot>
        <App/>
      </RecoilRoot>
    </React.StrictMode>
  )
}

if (rootElement.hasChildNodes()) {
  hydrate(<RootApp/>, rootElement);
} else {
  root.render(<RootApp/>);
}

