
const timeSince = (date, t) => {
  const seconds = (new Date().getTime() - new Date(date)) / 1000
  let interval = seconds / 31536000
  if (interval >= 1) {
    return t("time-since-seconds", {count: Math.floor(interval)})
  }
  interval = seconds / 2592000;
  if (interval >= 1) {
    return t("time-since-months", {count: Math.floor(interval)})
  }
  interval = seconds / 86400;
  if (interval >= 1) {
    return t("time-since-days", {count: Math.floor(interval)})
  }
  interval = seconds / 3600;
  if (interval >= 1) {
    return t("time-since-hours", {count: Math.floor(interval)})
  }
  interval = seconds / 60;
  if (interval >= 1) {
    return t("time-since-minutes", {count: Math.floor(interval)})
  }
  if (Math.floor(interval) <= 0) {
    return t("just-now")
  }
  return t("time-since-seconds", {count: Math.floor(interval)})
}

export default timeSince