import React, {useEffect, useState} from "react";

import Header from "../global/header";
import Footer from "../global/footer";
import Breadcrumbs from "../breadcrumbs";
import SingleBlogs from "../single-blogs";
import {Navigate, useLocation, useParams} from "react-router-dom";
import {useRecoilValue} from "recoil";
import {wpClientSelector} from "../../state/api";
import {Blog} from "../../models";
import PropTypes from "prop-types";

const SingleBlog = ({header, footer}) => {
  const {menu} = footer;
  const {slug} = useParams();
  const location = useLocation();
  const wpClient = useRecoilValue(wpClientSelector)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [blog, setBlog] = useState({})

  useEffect(() => {
    if (!slug || slug.length === 0) {
      return
    }
    setLoading(true)
    wpClient.blog()
      .embed()
      .slug(slug)
      .get()
      .then(function (response) {
        setLoading(false)
        if (response.length === 0) {
          setError(true)
          return
        }
        setBlog(new Blog(response[0]))
      })
      .catch(error => {
        console.error(error)
        setError(true)
        setLoading(false)
      });
  }, [slug]);

  if (loading) {
    return (
      <div id="preLoader"></div>
    )
  }

  if (error) {
    return <Navigate to="/404" state={{from: location}} replace/>
  }

  return (
    <>
      <Header header={header}/>
      <Breadcrumbs isBg="yes" title={blog.title}/>
      <SingleBlogs blog={blog}/>
      <Footer isBg="yes" menu={menu}/>
    </>
  );
};

SingleBlog.propTypes = {
  header: PropTypes.object.isRequired,
  footer: PropTypes.object.isRequired,
}

export default SingleBlog;
