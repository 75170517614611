import React, {useEffect, useState} from "react";

import './home-testimonials-item.scss'
import PropTypes from "prop-types";
import {useRecoilValue} from "recoil";
import {wpClientSelector} from "../../state/api";

const TestimonialsItem = ({testimonial}) => {
  const [avatar, setAvatar] = useState("")
  const wpClient = useRecoilValue(wpClientSelector)

  useEffect(() => {
    if (!testimonial) {
      return
    }
    wpClient.media().id(testimonial.author_avatar)
      .then(function (response) {
        setAvatar(response.source_url)
      })
      .catch(error => {
        console.error(error)
      });
  }, [testimonial]);

  return (
    <div
      className={`swiper-slide p-5px-lr ${testimonial.link && "pointer"}`}
      onClick={() => {
        if (!testimonial.link) {
          return
        }
        window.open(testimonial.link, '_blank');
      }}
    >
      <div className="testi-card card h-100 translateEffect1">
        <div className="card-body p-4">
          <div className="testi-card__quotation">
            <i className="icofont-quote-right"></i>
          </div>
          <p className="my-4">{testimonial.review}</p>
          <div className="testi-card__user-info pt-4">
            <div className="testimonial__user-info__image">
              <img src={avatar} alt={testimonial.author}/>
            </div>
            <div className="testimonial__user-info__content">
              <h4 className="mb-0">{testimonial.author}</h4>
              <p className="mb-0">{testimonial.author_role}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

TestimonialsItem.propTypes = {
  testimonial: PropTypes.object.isRequired,
}

export default TestimonialsItem