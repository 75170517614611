import {decodeHtml} from "../utils";

class Blog {
  constructor(wpBlog) {
    this.id = wpBlog.id
    this.title = decodeHtml(wpBlog.title.rendered)
    this.content = wpBlog.content.rendered
    this.excerpt = wpBlog.excerpt.rendered
    this.slug = wpBlog.slug
    this.date = new Date(wpBlog.date)
    this.commentsAllowed = wpBlog["comment_status"] === "open"
    if(wpBlog["_embedded"]) {
      if (wpBlog["_embedded"]["wp:featuredmedia"]) {
        this.imageUrl = wpBlog["_embedded"]["wp:featuredmedia"][0]["source_url"]
      }
      this.author = wpBlog["_embedded"].author[0]
      this.commentsNumber = (wpBlog["_embedded"].replies && wpBlog["_embedded"].replies[0]) ? wpBlog["_embedded"].replies[0].length : 0
    }
  }
}

export default Blog