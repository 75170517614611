import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import {useRecoilValue} from "recoil";
import {wpClientSelector} from "../state/api";
import {Book} from "../models";
import {Link} from "react-router-dom";

const Booksv1 = ({isBg, langTagId}) => {
  const {t} = useTranslation()
  const wpClient = useRecoilValue(wpClientSelector)
  const [books, setBooks] = useState([])
  const [cover1, setCover1] = useState("")
  const [cover2, setCover2] = useState("")
  const [cover3, setCover3] = useState("")
  const [cover4, setCover4] = useState("")
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  useEffect(() => {
    wpClient.books()
      .perPage(4)
      .embed()
      .param('meta_key', 'hide_from_recent')
      .param('meta_value', 0)
      .tags(langTagId)
      .then(function (response) {
        setBooks(response.map(item => new Book(item)))
        setLoading(false)
      })
      .catch(error => {
        console.error(error)
        setError(true)
        setLoading(false)
      });
  }, [langTagId]);

  useEffect(() => {
    if (books.length === 0) {
      return
    }
    books.forEach((book, index) => {
      wpClient.media().id(book.coverMediaId)
        .then(function (response) {
          setCover(index, response.source_url)
        })
        .catch(error => {
          console.error(error)
        });
    })
  }, [books]);

  const setCover = (index, url) => {
    switch (index) {
      case 0:
        setCover1(url);
        break;
      case 1:
        setCover2(url);
        break;
      case 2:
        setCover3(url);
        break;
      case 3:
        setCover4(url);
        break;
      default:
        break;
    }
  }

  if (loading || error || books.length === 0) {
    return (
      <></>
    )
  }

  return (
    // <!-- ========== Books section start ========== -->
    <section id="books" className={`booksv1 section-padding  ${isBg ? "bg-one" : ""}`}>
      <div className="container">
        <div className="row">
          <div className="col-xl-6 offset-xl-3 col-lg-10 offset-lg-1">
            <div className="section-title-center text-center">
              <span>{t("books")}</span>
              <h2 className="display-6">{t("some-of-my-books")}</h2>
              <div className="section-divider divider-traingle"></div>
            </div>
          </div>
        </div>
        <div className="row gx-5">
          <div className="col-lg-6 mb-4 mb-lg-0"
               data-aos="fade-right"
               data-aos-duration="1000"
               data-aos-delay="200">
            <div className="row">
              <div className="col-6">
                <div className="booksv1__img--wrap">
                  <div className="booksv1__img">
                    <Link to={`/books/${books[0].slug}`}>
                      <img
                        className="img-fluid"
                        src={cover1}
                        alt={books[0].title}
                      />
                    </Link>
                  </div>
                  <div className="booksv1__img">
                    <Link to={`/books/${books[1].slug}`}>
                      <img
                        className="img-fluid"
                        src={cover2}
                        alt={books[1].title}
                      />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="booksv1__img--wrap m-50px-t md-m-0px-t">
                  <div className="booksv1__img">
                    <Link to={`/books/${books[2].slug}`}>
                      <img
                        className="img-fluid"
                        src={cover3}
                        alt={books[2].title}
                      />
                    </Link>
                  </div>
                  <div className="booksv1__img">
                    <Link to={`/books/${books[3].slug}`}>
                      <img
                        className="img-fluid"
                        src={cover4}
                        alt={books[3].title}
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6" data-aos="fade-left" data-aos-duration="1000" data-aos-delay="200">
            <div className="booksv1__contnet">
              <div className="booksv1__contnet--badge mb-0">
                {t("must-have-books")}
              </div>
              <h3 className="display-6 m-30px-b">{t("the-most-loved-books")}</h3>
              <div className="booksv1__item--wrap">
                {books.map((data, i) => (
                  <div className="booksv1__item" key={i}>
                    <div className="booksv1__item--icon">
                      <img
                        className="img-fluid"
                        src="/assets/icons/touch.svg"
                        alt="Icon"
                        width="30"
                        height="30"
                      />
                    </div>
                    <Link to={`/books/${data.slug}`}>
                      <div className="booksv1__item--text">
                        <h3>{data.title}</h3>
                        <p>{data.shortDescription}</p>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
              <div style={{width: "100%", textAlign: "right", marginTop: "2rem"}}>
                <Link to="/books" className="button button__primary">
                  <span>{t("see-all-works")}</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    // <!-- ========== Books section end ========== -->
  )
    ;
};

Booksv1.propTypes = {
  isBg: PropTypes.bool.isRequired,
  langTagId: PropTypes.number.isRequired,
}

export default Booksv1;
