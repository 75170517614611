import React from "react";

import './single-comments-item.scss'
import PropTypes from "prop-types";
import {timeSince} from "../../utils";
import {useTranslation} from "react-i18next";

const SingleCommentsItem = ({comment}) => {
  const {t} = useTranslation()
  return (
    <div key={comment.id} className={`single-blog__comment__item bg-one ${comment.parent && "md-m-0px-l m-40px-l"}`}>
      <div className="single-blog__comment__item__image">
        <img
          className="img-fluid"
          src={comment.author_avatar_urls[96]}
          alt="mage"
        />
      </div>
      <div className="single-blog__comment__item__content">
        <div className="comment-author-info">
          <div className="row align-items-start">
            <div className="col-md-9 col-12 order-md-1 order-1">
              <h5>
                {
                  comment.author_url
                  ? <a href={comment.author_url} target="_blank">{comment.author_name}</a>
                  : comment.author_name
                }
              </h5>
              <p className="comment-date">
                {timeSince(comment.date, t)}
              </p>
            </div>
            <div className="col-md-3 col-sm-12 col-12 text-md-end order-md-2 order-sm-3 order-3">
            </div>
            <div className="col-md-12 col-sm-12 col-12 order-md-3 order-sm-2 order-2">
              <div className="comment-text" dangerouslySetInnerHTML={{__html: comment.content.rendered}}>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

SingleCommentsItem.propTypes = {
  comment: PropTypes.object.isRequired
}

export default SingleCommentsItem