
class Testimonial {
  constructor(wpTestimonial) {
    this.id = wpTestimonial.id
    this.slug = wpTestimonial.slug
    this.author = wpTestimonial.acf["author"]
    this.author_role = wpTestimonial.acf["author_role"]
    this.review = wpTestimonial.acf["review"]
    this.author_avatar = wpTestimonial.acf["author_avatar"]
    this.link = wpTestimonial.acf["link"]
  }
}

export default Testimonial