import React from "react";

import Header from "../global/header";
import Footer from "../global/footer";
import Breadcrumbs from "../breadcrumbs";
import AllBlogs from "../all-blogs";
import PropTypes from "prop-types";

const AllBlog = ({ header, footer }) => {
  const { menu } = footer;
  return (
    <>
      <Header header={header} />
      <Breadcrumbs isBg="yes" title="Blog" />
      <AllBlogs isBg={false} />
      <Footer isBg="yes" menu={menu} />
    </>
  );
};

AllBlog.propTypes = {
  header: PropTypes.object.isRequired,
  footer: PropTypes.object.isRequired,
}

export default AllBlog;
