import React, {useEffect, useState} from "react";
import CountUp from "react-countup";
import {useTranslation} from "react-i18next";
import {useRecoilValue} from "recoil";
import {wpClientSelector} from "../state/api";
import PropTypes from "prop-types";
import {Milestone} from "../models";

const FunFacts = ({ isBg, langTagId }) => {
  const {t} = useTranslation()
  const wpClient = useRecoilValue(wpClientSelector)
  const [milestones, setMilestones] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  useEffect(() => {
    wpClient.milestones()
      .perPage(5)
      .embed()
      .tags(langTagId)
      .then(function (response) {
        setMilestones(response.map(item => new Milestone(item)))
        setLoading(false)
      })
      .catch(error => {
        console.error(error)
        setError(true)
        setLoading(false)
      });
  }, [langTagId]);

  if (loading) {
    return (
      <></>
    )
  }

  return (
    // <!-- ========== Fun facts section start ========== -->
    <section
      className={`section-padding counters ${isBg ? "bg-one" : ""}`}
    >
      <div className="container">
        <div className="row">
          <div className="col-xl-6 offset-xl-3 col-lg-10 offset-lg-1">
            <div className="section-title-center text-center">
              <span>{t("awesome-stats")}</span>
              <h2 className="display-6">{t("all-milestone-achieved")}</h2>
              <div className="section-divider divider-traingle"></div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <ul className="counters__stats m-0 p-0 d-flex flex-wrap align-items-center justify-content-center">
              {milestones.map((data, i) => (
                <li
                  key={i}
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay={(i + 2) * 50}
                >
                  <div className="counters__stats-box h-100 translateEffect1">
                    <div className="counters__stats-icon">
                      <img
                        className="img-fluid"
                        src={`assets/icons/${data.icon}.svg`}
                        alt="icon"
                        width="100"
                        height="100"
                      />
                    </div>
                    <div className="counters__stats-box__number">
                      <CountUp end={data.number} enableScrollSpy="true" />
                    </div>
                    <h5>{data.name}</h5>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
    // <!-- ========== Fun facts section end ========== -->
  );
};

FunFacts.propTypes = {
  isBg: PropTypes.bool.isRequired,
  langTagId: PropTypes.number.isRequired,
}

export default FunFacts;
