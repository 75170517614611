import React from "react";

import Header from "../global/header";
import Footer from "../global/footer";
import Breadcrumbs from "../breadcrumbs";
import AllEvents from "../all-events";
import {useTranslation} from "react-i18next";

const AllEvent = ({header, footer}) => {
  const {menu} = footer;
  const {t} = useTranslation()
  return (
    <>
      <Header header={header}/>
      <Breadcrumbs isBg="yes" title={t("all-events")}/>
      <AllEvents isBg={false}/>
      <Footer isBg="yes" menu={menu}/>
    </>
  );
};

export default AllEvent;
