/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import './single-blogs.scss'

import {BiUser} from "react-icons/bi";
import {FaFacebookF, FaLinkedinIn, FaRegCalendarAlt, FaRegCommentDots, FaTwitter, FaYoutube} from "react-icons/fa";
import {IoLogoWhatsapp} from "react-icons/io";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import {timeSince} from "../../utils";
import SingleComments from "../single-comments";

const SingleBlogs = ({blog}) => {
  const {t} = useTranslation()

  return (
    <div id="single-blog" className="section-padding single-blog">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <article>
              {
                blog.imageUrl &&
                <div className="single-blog-cover">
                  <img
                    className="img-fluid"
                    src={blog.imageUrl}
                    alt={blog.title}
                  />
                </div>
              }
              <ul className="single-blog__metainfo">
                <li>
                  <BiUser/>
                  {blog.author.name}
                </li>
                <li>
                  <FaRegCalendarAlt/>
                  {timeSince(blog.date, t)}
                </li>
                {
                  blog.commentsAllowed &&
                  <li>
                    <FaRegCommentDots/>{t("comments", {count: blog.commentsNumber})}
                  </li>
                }
              </ul>
              <h2 className="display-5 single-blog__title">
                {blog.title}
              </h2>
              <div className="single-blog__para">
                <div dangerouslySetInnerHTML={{__html: blog.content}}></div>
              </div>
            </article>
            <div className="single-blog__share bg-one p-4">
              <strong>{t("share")}:</strong>
              <ul className="social-icon">
                <li>
                  <a href="https://www.facebook.com/">
                    <FaFacebookF/>
                  </a>
                </li>
                <li>
                  <a href="https://www.twitter.com/">
                    <FaTwitter/>
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/">
                    <FaLinkedinIn/>
                  </a>
                </li>
                <li>
                  <a href="https://www.youtube.com/">
                    <FaYoutube/>
                  </a>
                </li>
                <li>
                  <a href="https://www.whatsapp.com">
                    <IoLogoWhatsapp/>
                  </a>
                </li>
              </ul>
            </div>
            {
              blog.commentsAllowed &&
              <SingleComments singleId={blog.id}/>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

SingleBlogs.propTypes = {
  blog: PropTypes.object.isRequired,
}

export default SingleBlogs;