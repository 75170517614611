import React, {useEffect, useState} from "react";

import './single-comments.scss'
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import {useRecoilValue} from "recoil";
import {wpClientSelector} from "../../state/api";
import SingleCommentsItem from "../single-comments-item";
import SingleCommentsForm from "../single-comments-form";

const SingleComments = ({singleId}) => {
  const {t} = useTranslation()
  const wpClient = useRecoilValue(wpClientSelector)
  const [comments, setComments] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  useEffect(() => {
    wpClient
      .comments()
      .post(singleId)
      .perPage(100)
      .order('asc').orderby('date')
      .get()
      .then(function (response) {
        console.log(response)
        setComments(response)
        setLoading(false)
      })
      .catch(error => {
        console.error(error)
        setError(true)
        setLoading(false)
      });
  }, [singleId]);

  if (loading || error) {
    return <></>
  }

  return (<div className="single-blog__comment">
    <div className="single-blog__comment__title">
      <h4>
        {t("comments", {count: comments.length})}
      </h4>
    </div>
    <div className="single-blog__comment__wrap">
      {
        comments
          .filter(comment => comment.parent === 0)
          .map((comment, i) =>
            <div key={i}>
              <SingleCommentsItem key={i} comment={comment}/>
              {
                comments
                  .filter(childComment => childComment.parent === comment.id)
                  .map(childComment => <SingleCommentsItem key={i} comment={childComment}/>)
              }
            </div>
          )
      }
    </div>
    <SingleCommentsForm postId={singleId}/>
  </div>)
}

SingleComments.propTypes = {
  singleId: PropTypes.number.isRequired,
}

export default SingleComments