import i18next from "i18next";
import {initReactI18next} from "react-i18next";
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";

i18next
  .use(initReactI18next)
  .use(I18nextBrowserLanguageDetector)
  .init({
    debug: true,
    fallbackLng: "en",
    resources: {
      it: {
        translation: {
          "Home": "Home",
          "Capitoli": "Capitoli",
          "Autore": "Autore",
          "Riconoscimenti": "Riconoscimenti",
          "Libri": "Libri",
          "Recensioni": "Recensioni",
          "Contatti": "Contatti",
          "error": "Si è verificato un errore. Riprovare più tardi.",
          "about-author": "L'autrice",
          "learn-about-book-writer": "Scopri di più sull'autrice",
          "author-bio": "Antonella Roncarolo, insegnante e giornalista, vive e lavora a Grottammare nel Piceno. Operatrice culturale, scrive per il teatro, di cinema e di letteratura.",
          "Name": "Nome",
          "Born": "Data di nascita",
          "Country": "Provenienza",
          "Email ID": "Indirizzo Email",
          "Languages": "Lingue",
          "Address": "Indirizzo",
          "buy_now": "Acquista ora",
          "Events": "Eventi",
          "awesome-stats": "Qualche numero",
          "all-milestone-achieved": "I traguardi raggiunti",
          "Blog": "Blog",
          "Eventi": "Eventi",
          "books": "Libri",
          "some-of-my-books": "Libri pubblicati di recente",
          "must-have-books": "Libri da non perdere",
          "the-most-loved-books": "Alcuni tra i più amati",
          "cta-author-1": "Una lettura che soprende ad ogni pagina.",
          "readers-review": "Arcani sentieri",
          "readers-review-sub": "Piacevole e sorprendente - 5/5",
          "testimonials": "Recensioni",
          "what-people-said-about-my-books": "Cosa dicono dei miei libri",
          "contact": "Contattami",
          "write-me-anything": "Puoi scrivermi ciò che desideri",
          "sending": "Invio in corso",
          "your-quote-has-been-succesfully-sent": "Il tuo messaggio è stato inviato correttamente",
          "something-went-wrong": "Qualcosa è andato storto",
          "your-name": "Il tuo nome",
          "your-email": "Il tuo indirizzo e-mail",
          "subject": "Oggetto",
          "your-message": "Il tuo messaggio",
          "send-now": "Invia ora",
          "connect-author-socials": "Connettiti ai social dell'autore",
          "see-all-works": "Vedi tutti",
          "all-books": "Tutti i libri",
          "Books": "Libri",
          "loading-books-please-wait": "Caricamento dei libri in corso. Attendi qualche istante..",
          "comments_one": "{{count}} Commento",
          "comments_other": "{{count}} Commenti",
          "share": "Condividi",
          "name": "Nome",
          "email": "Indirizzo email",
          "website": "Sito web",
          "enter-comment-here": "Inserisci qui il tuo commento",
          "leave-a-comment": "Lascia un commento",
          "comments-form-indications": "Il tuo indirizzo email non verrà pubblicato. I campi obbligatori sono indicati.",
          "post-a-comment": "Invia il commento",
          "posting-comment": "Invio in corso..",
          "time-since-seconds_one": "{{count}} secondo fa",
          "time-since-seconds_other": "{{count}} secondi fa",
          "time-since-minutes_one": "{{count}} minuto fa",
          "time-since-minutes_other": "{{count}} minuti fa",
          "time-since-hours_one": "{{count}} ora fa",
          "time-since-hours_other": "{{count}} ore fa",
          "time-since-days_one": "{{count}} giorno fa",
          "time-since-days_other": "{{count}} giorni fa",
          "time-since-months_one": "{{count}} mese fa",
          "time-since-months_other": "{{count}} mesi fa",
          "time-since-years_one": "{{count}} anno fa",
          "time-since-years_other": "{{count}} anni fa",
          "reply": "Rispondi",
          "comment-waiting-for-approval": "Il tuo commento è stato inviato ed è in attesa di essere approvato.",
          "just-now": "Appena adesso",
          "loading-blog-please-wait": "Caricamento del blog in corso. Attendi qualche istante..",
          "loading-event-please-wait": "Caricamento degli eventi in corso. Attendi qualche istante..",
          "all-events": "Tutti gli eventi",
          "impossible-to-send-message": "Non è stato possibile inviare il tuo messaggio. Assicurati di aver compilato tutti i campi e riprova.",
          "message-sent-succesfully": "Il tuo messaggio è stato inviato. Grazie!",
        }
      },
      en: {
        translation: {
          "Home": "Home",
          "Capitoli": "Chapters",
          "Autore": "Author",
          "Riconoscimenti": "Achievements",
          "Libri": "Books",
          "Recensioni": "Testimonials",
          "Contatti": "Contact",
          "error": "An error occurred. Please try again later.",
          "about-author": "About author",
          "learn-about-book-writer": "Learn about book writer",
          "author-bio": "Antonella Roncarolo, a teacher and journalist, lives and works in Grottammare, in the Piceno region. A cultural operator, she writes about theater, cinema, and literature.",
          "Name": "Name",
          "Born": "Born",
          "Country": "Country",
          "Email ID": "Email ID",
          "Languages": "Languages",
          "Address": "Address",
          "buy_now": "Buy now",
          "Events": "Events",
          "awesome-stats": "A couple of numbers",
          "all-milestone-achieved": "All milestones achieved",
          "Blog": "Blog",
          "Eventi": "Events",
          "books": "Books",
          "some-of-my-books": "Latest released books",
          "must-have-books": "Must have books",
          "the-most-loved-books": "The most loved",
          "cta-author-1": "A reading that surprises you on every page.\"",
          "readers-review": "Arcani sentieri",
          "readers-review-sub": "Pleasant and surprising - 5/5",
          "testimonials": "Testimonials",
          "what-people-said-about-my-books": "What people said about my books",
          "contact": "Contact",
          "write-me-anything": "Write me anything",
          "sending": "Sending",
          "your-quote-has-been-succesfully-sent": "Your quote has been succesfully sent",
          "something-went-wrong": "Something went wrong",
          "your-name": "Your name",
          "your-email": "Your email",
          "subject": "Subject",
          "your-message": "Your message",
          "send-now": "Send now",
          "connect-author-socials": "Connect to author socials",
          "see-all-works": "See all",
          "all-books": "All books",
          "Books": "Books",
          "loading-books-please-wait": "Please wait while books are loading..",
          "comments_one": "{{count}} Comment",
          "comments_other": "{{count}} Comments",
          "share": "Share",
          "name": "Name",
          "email": "Email address",
          "website": "Website",
          "enter-comment-here": "Please Enter Your Comment Here",
          "leave-a-comment": "Leave a comment",
          "comments-form-indications": "Your email address will not be published. Required fields are marked.",
          "post-a-comment": "Post a comment",
          "posting-comment": "Posting comment..",
          "time-since-seconds_one": "{{count}} second ago",
          "time-since-seconds_other": "{{count}} seconds ago",
          "time-since-minutes_one": "{{count}} minute ago",
          "time-since-minutes_other": "{{count}} minutes ago",
          "time-since-hours_one": "{{count}} hour ago",
          "time-since-hours_other": "{{count}} hours ago",
          "time-since-days_one": "{{count}} day ago",
          "time-since-days_other": "{{count}} days ago",
          "time-since-months_one": "{{count}} month ago",
          "time-since-months_other": "{{count}} months ago",
          "time-since-years_one": "{{count}} year ago",
          "time-since-years_other": "{{count}} years ago",
          "reply": "Reply",
          "comment-waiting-for-approval": "Your comment has been published and it's waiting for approval.",
          "just-now": "Just now",
          "loading-blog-please-wait": "Please wait while blog is loading..",
          "loading-event-please-wait": "Please wait while events are loading..",
          "all-events": "All events",
          "impossible-to-send-message": "It was not possible to deliver your message. Check all mandatory fields and try again.",
          "message-sent-succesfully": "Your message has been delivered. Thank you!",
        }
      }
    }
  })