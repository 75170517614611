import {default as React, useState} from "react";
import {Button, Navbar} from "react-bootstrap";
import {GoChevronDown} from "react-icons/go";
import {NavLink} from "react-router-dom";
import {Link} from "react-scroll";
import {siteLogo} from "../../global";
import {useTranslation} from "react-i18next";
import i18next from "i18next";

const Header = ({header}) => {
  const [isActive, setActive] = useState(false);
  const [fix, setFix] = useState(false);
  const {t} = useTranslation()
  const langs = {
    it: {
      nativeName: "Italiano",
      icon: "/assets/icons/flags/it.png"
    },
    en: {
      nativeName: "English",
      icon: "/assets/icons/flags/en.png"
    },
  }

  const handleToggle = () => {
    setActive(!isActive);
  };

  function setFixed() {
    if (window.scrollY >= 100) {
      setFix(true);
    } else {
      setFix(false);
    }
  }

  window.addEventListener("scroll", setFixed);

  const LanguageSelector = () => {
    const [isLangSelectorActive, setIsLangSelectorActive] = useState(false);

    const handleLanguageToggle = () => {
      setIsLangSelectorActive(!isLangSelectorActive);
    };

    return (
      <li className="nav-item dropdown submenu" key="language-selector">
        <Link
          activeClass="active"
          className="nav-link scroll dropdown-toggle"
          to="#"
          spy={true}
          isDynamic={false}
          hashSpy={false}
          spyThrottle={500}
          smooth={true}
          duration={500}
          offset={15}
          data-bs-toggle="dropdown"
          aria-expanded="false">
          <img width={25} src={`/assets/icons/flags/${i18next.resolvedLanguage}.png`} alt="Language selector"/>
          <span onClick={handleLanguageToggle} className="sub-menu-toggle">
            <GoChevronDown/>
          </span>
        </Link>
        <ul className={isLangSelectorActive ? "dropdown-menu show" : "dropdown-menu"}>
          {
            Object.keys(langs).map((lang, i) => (
              <li key={i} className="nav-item">
                <Button
                  style={{textDecoration: "none", padding: "0"}}
                  variant="link"
                  size="md"
                  disabled={i18next.resolvedLanguage === lang}
                  onClick={() => i18next.changeLanguage(lang)}>
                  <img src={`/assets/icons/flags/${lang}.png`} width={15} alt={lang}/>
                  &nbsp;&nbsp;
                  {langs[lang].nativeName}
                </Button>
              </li>
            ))
          }
        </ul>
      </li>
    )
  }

  return (
    <header className={fix ? "header navbar_fixed" : "header"}>
      <div className="container">
        <div className="row">
          <Navbar bg="none" expand="lg">
            <a className="navbar-brand" href="/">
              <h1 className="m-0">{siteLogo.logoText}</h1>
              {/*<img src={siteLogo.logo} alt={siteLogo.alt}/>*/}
            </a>
            <Navbar.Toggle aria-controls="navbarSupportedContent">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </Navbar.Toggle>
            <Navbar.Collapse id="navbarSupportedContent">
              <ul className="navbar-nav menu ms-lg-auto">
                {
                  header.menu?.map((data, i) =>
                    data?.isDropdown === true ? (
                      <li className="nav-item dropdown submenu" key={i}>
                        <Link
                          activeClass="active"
                          className="nav-link scroll dropdown-toggle"
                          to={`${data.link}`}
                          spy={true}
                          isDynamic={false}
                          hashSpy={false}
                          spyThrottle={500}
                          smooth={true}
                          duration={500}
                          offset={15}
                          data-bs-toggle="dropdown"
                          aria-expanded="false">
                          {data.title}
                          <span onClick={handleToggle} className="sub-menu-toggle">
                          <GoChevronDown/>
                        </span>
                        </Link>
                        <ul
                          className={
                            isActive ? "dropdown-menu show" : "dropdown-menu"
                          }
                        >
                          {data.dropdownItem.map((item, i) => (
                            <li key={i} className="nav-item">
                              <NavLink to={item.link} className="nav-link">
                                {t(item.title)}
                              </NavLink>
                            </li>
                          ))}
                        </ul>
                      </li>
                    ) : (
                      <li className="nav-item" key={i}>
                        {
                          data.isPageLink
                            ? <Link
                              activeClass="active"
                              className="benefits nav-link"
                              to={`${data.link}`}
                              spy={true}
                              isDynamic={false}
                              hashSpy={false}
                              spyThrottle={500}
                              smooth={true}
                              duration={500}
                              offset={15}>
                                {t(data.title)}
                              </Link>
                            : <NavLink className="nav-link" to={data.link}>
                                {t(data.title)}
                              </NavLink>
                        }
                      </li>
                    )
                  )
                }
                <LanguageSelector/>
              </ul>
            </Navbar.Collapse>
          </Navbar>
        </div>
      </div>
    </header>
  );
};

export default Header;
