/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useRecoilValue} from "recoil";
import {wpClientSelector} from "../state/api";
import {Event} from "../models";
import {RiTimeFill} from "react-icons/ri";
import {timeSince} from "../utils";
import PropTypes from "prop-types";

const AllEvents = ({isBg}) => {
  const {t} = useTranslation()
  const wpClient = useRecoilValue(wpClientSelector)
  const [events, setEvents] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  useEffect(() => {
    wpClient.events()
      .perPage(50)
      .embed()
      .then(function (response) {
        setEvents(response.map(item => new Event(item)))
        setLoading(false)
      })
      .catch(error => {
        console.error(error)
        setError(true)
        setLoading(false)
      });
  }, []);

  if (loading || error) {
    return (
      <section id="book" className={`section-padding blog ${isBg ? "bg-one" : ""}`}>
        <div className="container">
          <div style={{textAlign: "center", padding: "50px"}}>
            {t("loading-event-please-wait")}
          </div>
        </div>
      </section>
    )
  }

  return (
    <section id="blog" className={`section-padding blog ${isBg ? "bg-one" : ""}`}>
      <div className="container">
        <div className="row">
          {events.map((data, i) => (
            <div
              key={i}
              className="col-md-6 col-lg-4 mb-4"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay={(i + 2) * 50}>
              <article className="blog__single-post h-100 translateEffect1">
                <div className="blog__single-post__image">
                  <Link to={`/events/${data.slug}`}>
                    <img className="img-fluid" src={data.imageUrl} alt="Blog 01"/>
                  </Link>
                </div>
                <div className="blog__single-post__body">
                  <div className="blog__single-post__content">
                    <h2 className="fs-4">
                      <Link to={`/events/${data.slug}`}>{data.title}</Link>
                    </h2>
                    <p className="m-0" dangerouslySetInnerHTML={{__html: data.excerpt}}/>
                  </div>
                  <div className="d-flex gap-2 align-items-center">
                    <RiTimeFill/>
                    {timeSince(data.date, t)}
                  </div>
                </div>
              </article>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
};

AllEvents.propTypes = {
  isBg: PropTypes.bool.isRequired,
}

export default AllEvents;
