import React from "react";

import Header from "../global/header";
import Footer from "../global/footer";
import Breadcrumbs from "../breadcrumbs";
import AllBooks from "../all-books";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";

const AllBook = ({ header, footer, langTagId }) => {
  const { menu } = footer;
  const {t} = useTranslation()
  return (
    <>
      <Header header={header} />
      <Breadcrumbs isBg="yes" title={t("all-books")} />
      <AllBooks isBg={false} langTagId={langTagId} />
      <Footer isBg="yes" menu={menu} />
    </>
  );
}

AllBook.propTypes = {
  langTagId: PropTypes.number.isRequired,
  header: PropTypes.object.isRequired,
  footer: PropTypes.object.isRequired,
}

export default AllBook;
