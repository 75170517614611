/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useRecoilValue} from "recoil";
import {wpClientSelector} from "../state/api";
import {Book} from "../models";
import PropTypes from "prop-types";
import BookItem from "./book-item";

const AllBooks = ({isBg, langTagId}) => {
  const {t} = useTranslation()
  const wpClient = useRecoilValue(wpClientSelector)
  const [books, setBooks] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  useEffect(() => {
    wpClient.books()
      .perPage(50)
      .embed()
      .tags(langTagId)
      .then(function (response) {
        setBooks(response.map(item => new Book(item)))
        setLoading(false)
      })
      .catch(error => {
        console.error(error)
        setError(true)
        setLoading(false)
      });
  }, [langTagId]);

  if (loading || error) {
    return (
      <section id="book" className={`section-padding blog ${isBg ? "bg-one" : ""}`}>
        <div className="container">
          <div style={{textAlign: "center", padding: "50px"}}>
            {t("loading-books-please-wait")}
          </div>
        </div>
      </section>
    )
  }

  return (
    <section
      id="blog"
      className={`section-padding blog ${isBg ? "bg-one" : ""}`}
    >
      <div className="container">
        <div className="row">
          {books.map((data, i) => (
            <div
              key={i}
              className="col-md-6 col-lg-4 mb-4"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay={(i + 2) * 50}>
              <BookItem book={data}/>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

AllBooks.propTypes = {
  isBg: PropTypes.bool.isRequired,
  langTagId: PropTypes.number.isRequired,
}

export default AllBooks;
