import React from "react";

import './single-events.scss'

import {FaFacebookF, FaLinkedinIn, FaRegCalendarAlt, FaRegCommentDots, FaTwitter, FaYoutube} from "react-icons/fa";
import {IoLogoWhatsapp} from "react-icons/io";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";
import {BiUser} from "react-icons/bi";
import {timeSince} from "../../utils";
import SingleComments from "../single-comments";

const SingleEvents = ({event}) => {
  const {t} = useTranslation()

  return (
    <div id="single-blog" className="section-padding single-blog">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <article>
              {
                event.imageUrl &&
                <div className="single-blog-cover">
                  <img
                    className="img-fluid"
                    src={event.imageUrl}
                    alt={event.title}
                  />
                </div>
              }
              <ul className="single-blog__metainfo">
                <li>
                  <BiUser/>
                  {event.author.name}
                </li>
                <li>
                  <FaRegCalendarAlt/>
                  {timeSince(event.date, t)}
                </li>
                {
                  event.commentsAllowed &&
                  <li>
                    <FaRegCommentDots/>{t("comments", {count: event.commentsNumber})}
                  </li>
                }
              </ul>
              <h2 className="display-5 single-blog__title">
                {event.title}
              </h2>
              <div className="single-blog__para">
                <div dangerouslySetInnerHTML={{__html: event.content}}></div>
              </div>
            </article>
            <div className="single-blog__share bg-one p-4">
              <strong>{t("share")}:</strong>
              <ul className="social-icon">
                <li>
                  <a href="https://www.facebook.com/">
                    <FaFacebookF/>
                  </a>
                </li>
                <li>
                  <a href="https://www.twitter.com/">
                    <FaTwitter/>
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/">
                    <FaLinkedinIn/>
                  </a>
                </li>
                <li>
                  <a href="https://www.youtube.com/">
                    <FaYoutube/>
                  </a>
                </li>
                <li>
                  <a href="https://www.whatsapp.com">
                    <IoLogoWhatsapp/>
                  </a>
                </li>
              </ul>
            </div>
            {
              event.commentsAllowed &&
              <SingleComments singleId={event.id}/>
            }
          </div>
        </div>
      </div>
    </div>
  );
}

SingleEvents.propTypes = {
  event: PropTypes.object.isRequired,
}

export default SingleEvents;
