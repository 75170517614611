import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {Book} from "../models";
import {useRecoilValue} from "recoil";
import {wpClientSelector} from "../state/api";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

const Hero = ({isBg, langTagId}) => {
  const wpClient = useRecoilValue(wpClientSelector)
  const {t} = useTranslation()
  const [book, setBook] = useState({})
  const [cover, setCover] = useState("")
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  useEffect(() => {
    wpClient.books()
      .perPage(1)
      .embed()
      .param('meta_key', 'featured')
      .param('meta_value', 1)
      .tags(langTagId)
      .then(function (response) {
        setBook(new Book(response[0]))
        setLoading(false)
      })
      .catch(error => {
        console.error(error)
        setError(true)
        setLoading(false)
      });
  }, [langTagId]);

  useEffect(() => {
    if (!book.coverMediaId) {
      return
    }
    wpClient.media().id(book.coverMediaId)
      .then(function (response) {
        setCover(response.source_url)
      })
      .catch(error => {
        console.error(error)
      });
  }, [book]);

  if (loading) {
    return (
      <></>
    )
  }

  return (
    // <!-- ========== Hero section start ========== -->
    <section id="hero" className={`hero hero__padding overflow-hidden position-relative ${isBg ? "bg-one" : ""}`}>
      <div className="circle x1"></div>
      <div className="circle x2"></div>
      <div className="circle x3"></div>
      <div className="circle x4"></div>
      <div className="circle x5"></div>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 m-0px-b md-m-30px-b">
            <div className="hero__content position-relative">
              <div className="badge-text mb-2 text-uppercase">
                {book.slogan}
              </div>
              <h1 className="display-4 mb-4 text-capitalize">{book.title}</h1>
              <p className="text-muted mb-5 fs-5">{book.shortDescription}</p>
              {
                book.ctaText &&
                <a href={book.ctaLink} target="_blank" className="button button__primary">
                  <span>{t(book.ctaText)}</span>
                </a>
              }
            </div>
          </div>
          <div className="col-lg-6">
            <div className="hero__images text-center">
              <Link to={`/books/${book.slug}`}>
                <img style={{maxHeight: "550px"}}
                     className="img-fluid"
                     src={cover} alt=""
                />
              </Link>
              <div className="hero__images--badge">
                <span>
                  <span className="hero__images--badge--text1">
                    IT
                  </span>
                  <span className="hero__images--badge--text2">
                    {book.tag}
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    // <!-- ========== Hero section end ========== -->
  );
};

Hero.propTypes = {
  isBg: PropTypes.bool.isRequired,
  langTagId: PropTypes.number.isRequired,
}

export default Hero;
