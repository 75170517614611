import {decodeHtml} from "../utils";

class Milestone {
  constructor(wpMilestone) {
    this.id = wpMilestone.id;
    this.title = decodeHtml(wpMilestone.title.rendered)
    this.slug = wpMilestone.slug;
    this.name = wpMilestone.acf["name"]
    this.number = wpMilestone.acf["number"]
    this.icon = wpMilestone.acf["icon"]
  }
}

export default Milestone