/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react';

import './single-books.scss'

import {FaFacebookF, FaLinkedinIn, FaRegCalendarAlt, FaRegCommentDots, FaTwitter, FaYoutube} from "react-icons/fa";
import {IoLogoWhatsapp} from "react-icons/io";
import {useRecoilValue} from "recoil";
import {wpClientSelector} from "../../state/api";
import {useTranslation} from "react-i18next";
import SingleComments from "../single-comments";
import PropTypes from "prop-types";

const SingleBooks = ({book}) => {
  const wpClient = useRecoilValue(wpClientSelector)
  const [cover, setCover] = useState("")
  const {t, i18n} = useTranslation()

  useEffect(() => {
    if (!book.coverMediaId) {
      return
    }
    wpClient.media().id(book.coverMediaId)
      .then(function (response) {
        setCover(response.source_url)
      })
      .catch(error => {
        console.error(error)
      });
  }, [book]);

  return (
    <div id="single-blog" className="section-padding single-blog">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <article>
              <div className="single-books-cover">
                <img
                  className="img-fluid"
                  src={cover}
                  alt={`cover ${book.title}`}
                />
              </div>
              <ul className="single-blog__metainfo">
                <li style={{textTransform: "capitalize"}}>
                  <FaRegCalendarAlt/>
                  {
                    book.releaseDate.toLocaleString(i18n.resolvedLanguage, {month: 'long'}) + " " + book.releaseDate.getFullYear()
                  }
                </li>
                {
                  book.commentsAllowed &&
                  <li>
                    <FaRegCommentDots/>{t("comments", {count: book.commentsNumber})}
                  </li>
                }
              </ul>
              <h2 className="display-5 single-blog__title">
                {book.title}
              </h2>
              <div className="single-blog__para">
                <div dangerouslySetInnerHTML={{__html: book.content}}></div>
              </div>
            </article>
            {
              book.ctaLink &&
              <div style={{marginBottom: "2rem"}} className="single-blog__share bg-one p-4">
                <strong>{t(book.ctaText)}:</strong>
                <a className="button button__primary" href={book.ctaLink}
                   target="_blank"><span>{t(book.ctaText)}</span></a>
              </div>
            }
            <div className="single-blog__share bg-one p-4">
              <strong>{t("share")}:</strong>
              <ul className="social-icon">
                <li>
                  <a href="https://www.facebook.com/">
                    <FaFacebookF/>
                  </a>
                </li>
                <li>
                  <a href="https://www.twitter.com/">
                    <FaTwitter/>
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/">
                    <FaLinkedinIn/>
                  </a>
                </li>
                <li>
                  <a href="https://www.youtube.com/">
                    <FaYoutube/>
                  </a>
                </li>
                <li>
                  <a href="https://www.whatsapp.com">
                    <IoLogoWhatsapp/>
                  </a>
                </li>
              </ul>
            </div>
            {
              book.commentsAllowed &&
              <SingleComments singleId={book.id}/>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

SingleBooks.propTypes = {
  book: PropTypes.object.isRequired,
}

export default SingleBooks;