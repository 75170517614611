/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from "react";
import Breadcrumbs from "../breadcrumbs";
import Footer from "../global/footer";
import Header from "../global/header";
import SingleEvents from "../single-events";
import {Navigate, useLocation, useParams} from "react-router-dom";
import {useRecoilValue} from "recoil";
import {wpClientSelector} from "../../state/api";
import {Event} from "../../models"
import PropTypes from "prop-types";

const SingleEvent = ({header, footer}) => {
  const {menu} = footer;
  const {slug} = useParams();
  const location = useLocation();
  const wpClient = useRecoilValue(wpClientSelector)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [event, setEvent] = useState({})

  useEffect(() => {
    if (!slug || slug.length === 0) {
      return
    }
    setLoading(true)
    wpClient.events()
      .embed()
      .slug(slug)
      .get()
      .then(function (response) {
        setLoading(false)
        if (response.length === 0) {
          setError(true)
          return
        }
        setEvent(new Event(response[0]))
      })
      .catch(error => {
        console.error(error)
        setError(true)
        setLoading(false)
      });
  }, [slug]);

  if (loading) {
    return (
      <div id="preLoader"></div>
    )
  }

  if (error) {
    return <Navigate to="/404" state={{from: location}} replace/>
  }

  return (
    <>
      <Header header={header}/>
      <Breadcrumbs isBg="yes" title={event.title}/>
      <SingleEvents event={event}/>
      <Footer isBg="yes" menu={menu}/>
    </>
  );
}

SingleEvent.propTypes = {
  header: PropTypes.object.isRequired,
  footer: PropTypes.object.isRequired,
}

export default SingleEvent;
