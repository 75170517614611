import React, {useEffect, useState} from "react";

import './book-item.scss'
import {Link} from "react-router-dom";
import PropTypes from "prop-types";
import {useRecoilValue} from "recoil";
import {wpClientSelector} from "../../state/api";
import {RiTimeFill} from "react-icons/ri";
import {useTranslation} from "react-i18next";

const BookItem = ({ book }) => {
  const wpClient = useRecoilValue(wpClientSelector)
  const [cover, setCover] = useState("")
  const {i18n} = useTranslation()

  useEffect(() => {
    if (!book || !book.coverMediaId) {
      return
    }
    wpClient.media().id(book.coverMediaId)
      .then(function (response) {
        setCover(response.source_url)
      })
      .catch(error => {
        console.error(error)
      });
  }, [book]);

  return (
    <article className="blog__single-post h-100 translateEffect1">
      <div className="blog__single-post__image">
        <Link to={`/books/${book.slug}`}>
          <img src={cover} className="img-fluid book-item-cover" alt={`cover ${book.title}`}/>
        </Link>
      </div>
      <div className="blog__single-post__body">
        <div className="blog__single-post__content">
          <h2 className="fs-4">
            <Link to={`/books/${book.slug}`}>{book.title}</Link>
          </h2>
          <p className="m-0">{book.shortDescription}</p>
        </div>
      </div>
      <div className="blog__single-post__meta">
        <div className="d-flex gap-2 align-items-center">
          <RiTimeFill/>
          <span className="book-item-release-date">
            {
              book.releaseDate.toLocaleString(i18n.resolvedLanguage, { month: 'long' }) + " " + book.releaseDate.getFullYear()
            }
          </span>
        </div>
      </div>
    </article>
  )
}

BookItem.propTypes = {
  book: PropTypes.object.isRequired,
}

export default BookItem