import {decodeHtml} from "../utils";

class Event {
  constructor(wpEvent) {
    this.id = wpEvent.id
    this.title = decodeHtml(wpEvent.title.rendered)
    this.content = wpEvent.content.rendered
    this.excerpt = wpEvent.excerpt.rendered
    this.slug = wpEvent.slug
    this.date = new Date(wpEvent.date)
    this.commentsAllowed = wpEvent["comment_status"] === "open"
    if(wpEvent["_embedded"]) {
      if (wpEvent["_embedded"]["wp:featuredmedia"]) {
        this.imageUrl = wpEvent["_embedded"]["wp:featuredmedia"][0]["source_url"]
      }
      this.author = wpEvent["_embedded"].author[0]
      this.commentsNumber = (wpEvent["_embedded"].replies && wpEvent["_embedded"].replies[0]) ? wpEvent["_embedded"].replies[0].length : 0
    }
  }
}

export default Event