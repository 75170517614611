/* eslint-disable jsx-a11y/img-redundant-alt */
import React, {useEffect, useState} from "react";
import {Autoplay, Pagination} from "swiper";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/pagination";
import {Swiper, SwiperSlide} from "swiper/react";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";
import {useRecoilValue} from "recoil";
import {wpClientSelector} from "../state/api";
import {Testimonial} from "../models";
import TestimonialsItem from "./home-testimonials-item";

const Testimonials = ({isBg, langTagId}) => {
  const wpClient = useRecoilValue(wpClientSelector)
  const {t} = useTranslation()
  const [testimonials, setTestimonials] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  useEffect(() => {
    wpClient.testimonials()
      .perPage(50)
      .embed()
      .tags(langTagId)
      .then(function (response) {
        setTestimonials(response.map(item => new Testimonial(item)))
        setLoading(false)
      })
      .catch(error => {
        console.error(error)
        setError(true)
        setLoading(false)
      });
  }, [langTagId]);

  if (loading || error || testimonials.length === 0) {
    return (
      <></>
    )
  }

  return (
    // <!-- ========== Testimonial section start ========== -->
    <section id="reviews" className={`section-padding testimonial ${isBg ? "bg-one" : ""}`}>
      <div className="container">
        <div className="row">
          <div className="col-xl-6 offset-xl-3 col-lg-10 offset-lg-1">
            <div className="section-title-center text-center">
              <span>{t("testimonials")}</span>
              <h2 className="display-6">{t("what-people-said-about-my-books")}</h2>
              <div className="section-divider divider-traingle"></div>
            </div>
          </div>
        </div>
        <div className="row testi-row">
          <div className="col-12">
            <Swiper
              modules={[Pagination, Autoplay]}
              pagination={{clickable: true}}
              autoplay
              breakpoints={{
                768: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
              }}
            >
              {testimonials.map((data) => (
                <SwiperSlide key={data.id}>
                  <TestimonialsItem testimonial={data} />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </section>
    // <!-- ========== Testimonial section end ========== -->
  );
};

Testimonials.propTypes = {
  isBg: PropTypes.bool.isRequired,
  langTagId: PropTypes.number.isRequired,
}

export default Testimonials;
